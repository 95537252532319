<template>
  <div class="bg bg1 ">
    <div class="class--con">
      <class-but i-s-throw-out @backOfff="backOfff" @forward="forward"/>
      <div class="class--container">
        <div class="class--title">破冰小游戏</div>
        <div class="class--container__info">
          <div>
            <div>
              <div class="class--title__ontent">
                Q{{ currentPage }}：{{ nowQuestionList.desc }}
                <span class="class--title__ontent__but"
                      v-if="nowQuestionList.isSHow && nowQuestionList.topicType == 1"
                      @click="isSHowClick(nowQuestionList)">显示答案</span>
              </div>
              <div v-if="nowQuestionList.img" style="text-align: center">
                <!--                  style="max-width: 199px;"-->
                <img :src="nowQuestionList.img" alt="" style="max-width: 600px">
              </div>
              <div class="class--title__img">
                <div v-for="(ii,kk) in nowQuestionList.options" :key="kk">
                  <img v-if="showAnswerImg(nowQuestionList) && ii.infoImg" :src="ii.infoImg" alt="">
                  <div v-if="ii.info || ii.infoImg">{{ ii.title }}、 {{ ii.info }}</div>
                </div>
              </div>
              <div>
                <span style="font-size: 32px;color:#F11F62;" v-if="!nowQuestionList.isSHow">正确答案：</span>
                <span style="font-size: 32px;color:#2B3654;"
                      v-if="!nowQuestionList.isSHow">{{ nowQuestionList.answer }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ClassBut from "@/views/conference/ConferenceItem/classBut.vue";
import service from "@/utils/axios";

export default {
  name: "twoPage",
  components: {ClassBut},
  data() {
    return {
      isSHow: true,
      reportTopicList: [],//全部数据
      totalPage: 1, // 统共页数，默认为1
      currentPage: 1, //当前页数 ，默认为1
      pageSize: 1, // 每页显示数量
      currentPageData: [], //当前页显示内容
    }
  },
  created() {
    this.getApireportTopic()
  },
  computed: {
    nowQuestionList() {
      return this.reportTopicList.length > 0 ? this.reportTopicList[this.currentPage - 1] : []
    }
  },
  methods: {
    forward() {
      //下一页
      if (this.currentPage === this.totalPage) {
        this.$router.push({
          name: 'howAreWeLearning',
          query: this.$route.query
        })
        return
      }
      this.currentPage++;
      // this.setCurrentPageData();
    },
    async getApireportTopic() {

      let res = await service.post('/dwart/an_teaching/hbk/v1/search/reportTopic', {
        classId: this.$route.query.classId,
      })

      this.setCurrentPageData(res)
      if (res === null) {
        await this.$router.replace({
          name: 'PageThird',
          query: this.$route.query
        })
      }
    },
    setCurrentPageData(list) {
      let newList = list.filter(v => !!v.desc)

      this.reportTopicList = newList.map(v => {
        return {...v, isSHow: true}
      })
      this.totalPage = newList.length

      //
      //
      // this.totalPage = Math.ceil(this.reportTopicList.length / this.pageSize);
      // this.totalPage = this.totalPage == 0 ? 1 : this.totalPage;
      // let begin = (this.currentPage - 1) * this.pageSize;
      // let end = this.currentPage * this.pageSize;
      // this.currentPageData = this.reportTopicList.slice(begin, end);
    },
    backOfff() {
      //上一页
      if (this.currentPage === 1) {
        this.$router.back(-1)
        return
      }
      this.currentPage--;
      this.setCurrentPageData();
    },
    isSHowClick(i, k) {
      i.isSHow = false
    },
    showAnswerImg(nowQuestionList) {
      return !(nowQuestionList.img && nowQuestionList.options.filter(v => v.infoImg).length > 0)
    }
  }
}
</script>

<style lang="less" scoped>
@import "reportClass.less";

.class--container {
  padding: 60px 65px 0 65px;
  .class--title {
    font-size: 60px;
    font-weight: bold;
    color: #2B3654;
  }

  .class--title__ontent {
    color: #2B3654;
    font-size: 24px;
    margin: 60px 0;

    .class--title__ontent__but {
      border: 2px solid #F11F62;
      border-radius: 30px;
      display: inline-block;
      height: 44px;
      width: 120px;
      font-size: 20px;
      color: #F11F62;
      text-align: center;
      line-height: 44px;
      cursor: pointer;
    }

    .class--title__ontent__but:hover {
      background-color: #F11F62;
      color: #fff;
    }

  }

  .class--title__img {
    display: flex;
    justify-content: space-evenly;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #2B3654;
      font-size: 32px;
      margin-top: 10px;
    }

    img {
      width: 510px;
      height: 382px;
    }
  }
}

.class--container__info {
  width: 100%;
  height: 700px;
  overflow-y: auto;
  img{
    border: 3px solid #FF9900;
    border-radius: 10px;
  }

}

::-webkit-scrollbar {
  display: none;
}

</style>
